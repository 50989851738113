import React from 'react'
import Banner from './Banner'
import Description from './Description'
import RelatedPosts from './RelatedPosts'

const BlogDetails:React.FC = () => {
  return (
    <div>
      <Banner/>
      <Description/>
      {/* <RelatedPosts/> */}
    </div>
  )
}

export default BlogDetails













// import React, { useEffect, useState } from 'react'
// import Banner from './Banner'
// import Description from './Description'
// import RelatedPosts from './RelatedPosts'
// import { useParams } from 'react-router-dom'
// import { blogData } from './blogdata'

// const BlogDetails: React.FC = () => {
//   const { id } = useParams<{ id: string }>() // Getting the blog ID from the URL
//   const [blog, setBlog] = useState<any>(null)

//   useEffect(() => {
//     if (id) {
//       const fetchedBlog = blogData.find((blog: any) => blog.id === parseInt(id)) // Assuming ID is numeric
//       setBlog(fetchedBlog)
//     }
//   }, [id])

//   if (!blog) return <div>Loading...</div>

//   console.log(blog, 'blog');

//   return (
//     <div>
//       <Banner blog={blog} />
//       <Description blog={blog} />
//     </div>
//   )
// }

// export default BlogDetails
