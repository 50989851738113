import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, List, ListItem } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { servicebannerone } from '../../../assets';

const tabsData = [
  {
    id: 1,
    bannerImage: servicebannerone, 
    title: "Custom Designed Sales Mechanisms",
    description: "Selling a property is more than just putting it on the market—it’s about understanding its value, showcasing its strengths, and connecting with the right buyers. At Deccan Progressive Realty, we specialize in creating Custom Designed Sales Mechanisms to help you sell your property faster, smarter, and at the best possible value. \n \n With our headquarters in Hyderabad, we bring deep insights into the local real estate market, combining our expertise with innovative strategies to deliver exceptional results. Whether you’re selling a residential property, commercial space, or land, we design tailored solutions that align with your goals and ensure a seamless sales experience.  ",
    sections: [
      {
        sectionTitle: "How Our Custom Designed Sales Mechanisms Work",
        subsections: [
          {
            title: "Property Evaluation: Understanding Your Asset",
            details: [
              "Identifying key selling points such as location, design, amenities, and potential.",
              "Assessing the property’s current market value through comparative analysis.",
              "Highlighting any improvements or staging opportunities to enhance its appeal."
            ]
          },
          {
            title: "Strategic Sales Planning: Crafting Your Roadmap  ",
            details: [
              "Target Audience Identification:** Understanding who your ideal buyers are and tailoring messaging to appeal to them.",
              "Marketing Plan Creation: Leveraging both online and offline channels to maximize visibility, including social media, real estate platforms, email campaigns, and local outreach. ",
              "Pricing Strategy: Setting a competitive yet profitable price based on market data, trends, and your expectations."
            ]
          },
          {
            title: "Seamless Execution: Bringing the Plan to Life",
            details: [
              "Professional Marketing: Using high-quality photography, videos, and virtual tours to showcase your property.",
              "Widespread Advertising: Listing your property on top real estate platforms, social media channels, and leveraging our network for maximum exposure.",
              "Buyer Engagement: Conducting open houses, private viewings, and virtual showings to attract serious buyers.",
              "Negotiation Support: Our experienced team handles negotiations with potential buyers, ensuring you get the best deal while protecting your interests.",
              "Closing Assistance: From documentation to legal formalities, we guide you through the final steps for a smooth transaction."
            ]
          },
          {
            title: "Our Tools and Techniques",
            details: [
              "Market Research Tools: Advanced analytics to understand trends and set the right price.",
              "Digital Marketing Expertise: Using SEO, targeted ads, and social media campaigns to amplify your property’s reach. ",
              "Home Staging Recommendations: Helping you create an inviting atmosphere that appeals to potential buyers.  ",
              "Professional Photography and Videography: Capturing your property in its best light to make a lasting impression.  ",
            ]
          },
          {
            title: "Our Proven Track Record",
            details: [
              "Faster Sales Timelines: Properties sold within 30% less time than the market average.  ",
              "Increased ROI: Clients consistently achieve higher-than-expected sale prices due to our strategic pricing and marketing.  ",
              "Satisfied Clients: A growing list of happy property owners who recommend us for our professionalism, dedication, and results. ",
            ]
          },
          {
            title: "What Makes Hyderabad’s Market Unique?  ",
            details: [
              "A booming IT and tech industry attracting professionals and investors. ",
              "Affordable yet appreciating property rates compared to other metropolitan cities. ",
              "Increasing demand for premium properties in key areas like Jubilee Hills, Gachibowli, and Madhapur.  ",
              "A mix of traditional and modern architectural appeal that caters to diverse buyer preferences."
            ]
          },
          {
            title : "Client-Centric Approach",
            details: [
              "Regular Updates: We keep you informed at every stage of the sales process.  ",
              "Clear Communication: No hidden costs or surprises—just honest advice and straightforward processes.",
              "After-Sales Support: Need assistance after the sale? We’re here to help with property handovers and beyond.  ",
            ]

          }
        ]
      },
      {
        sectionTitle: "Why Choose Deccan Progressive Realty for Your Sales Needs?",
        subsections: [
          "Tailored Solutions: We don’t believe in one-size-fits-all strategies. Every property has its unique features, and we design sales mechanisms that highlight these attributes to attract the right buyers",
          "Local Expertise: With years of experience in Hyderabad’s dynamic real estate market, we understand the trends, buyer behavior, and pricing strategies needed to stand out.",
          "Faster Sales: Using smart marketing techniques, strategic outreach, and a vast network, we ensure your property reaches the right audience, reducing the time it spends on the market. ",
          "Maximum Value: Our data-driven approach to pricing ensures your property is positioned competitively to get the best returns while avoiding underpricing or overpricing.",
          "Comprehensive Support: From property evaluation to deal closure, we provide end-to-end support, handling everything so you can focus on what matters most to you."
        ]
      }
    ]
  },
  {
    id: 2,
    bannerImage: servicebannerone, 
    title: "Brand Consulting & Enhanced Customer Experience",
    description: "In a competitive real estate market like Hyderabad, standing out requires more than just exceptional projects—it demands a strong, resonant brand and unparalleled customer experiences. At Deccan Progressive Realty, we specialize in Brand Consulting and Enhanced Customer Experience, helping real estate developers and property businesses build memorable brands and foster trust with their audiences.  \n \n Whether you’re launching a new project, rebranding, or looking to elevate customer satisfaction, our tailored services deliver results that drive long-term success. ",
    sections: [
      {
        sectionTitle: "Key Features of Our Sales Mechanisms",
        subsections: [
          {
            title: "Market Analysis & Insights",
            details: [
              "Comprehensive research to understand market trends, target audience behavior, and competitive landscapes.",
              "Data-driven strategies that align with buyer preferences and industry forecasts."
            ]
          },
          {
            title: "Innovative Sales Strategies",
            details: [
              "Development of customized campaigns tailored to your project’s strengths and USP (Unique Selling Proposition).",
              "Integration of digital and traditional marketing tools to create a cohesive sales approach."
            ]
          },
          {
            title: "Lead Generation & Nurturing",
            details: [
              "Advanced CRM systems to identify, track, and manage potential buyers effectively.",
              "Automated lead-nurturing workflows to keep prospects engaged throughout their decision-making journey."
            ]
          },
          {
            title: "Brand Consulting & Customer Experience Enhancement",
            details: [
              "Brand Identity Development: Crafting a unique brand story, logo, and visual elements that resonate with your target audience.",
              "Customer Journey Mapping: Understanding the buyer’s path from discovery to purchase and beyond, to enhance every touchpoint.",
              "Loyalty Programs & Incentives: Creating rewards and benefits that keep customers engaged and coming back for more.",
              "Customer Feedback & Surveys: Collecting insights to improve your services, products, and overall customer experience."
            ]
          },
          {
            title: "Digital Transformation & Technology Integration",
            details: [
              "Digital Marketing Strategies: Leveraging online channels to reach a wider audience and drive engagement.",
              "CRM & Automation Tools: Implementing systems that streamline customer interactions, lead management, and sales processes.",
              "Data Analytics & Reporting: Using data to track performance, identify trends, and make informed decisions for your brand.",
              "Customer Service Training: Equipping your team with the skills and knowledge to deliver exceptional service at every touchpoint."
            ]
          }
        ]
      },
      {
        sectionTitle: "Why Brand Consulting Matters in Real Estate",
        subsections: [
          "Expertise in designing sales strategies for both residential and commercial properties.",
          "Proven track record of success in boosting sales and enhancing client satisfaction.",
          "Commitment to delivering solutions that align with your business goals and brand identity.",
          "Brand Consulting & Customer Experience Enhancement",
        ]
      }
    ]
  },
  {
    id: 3,
    bannerImage: servicebannerone, 
    title: "Tech Tools & Automation Systems Implementation",
    description: "At Deccan Progressive Realty, we believe in creating innovative and tailored strategies to maximize sales for your real estate projects. Our custom-designed sales mechanisms are crafted to meet the unique needs of each client, ensuring that every property stands out in the competitive market.",
    sections: [
      {
        sectionTitle: "Key Features of Our Sales Mechanisms",
        subsections: [
          {
            title: "Market Analysis & Insights",
            details: [
              "Comprehensive research to understand market trends, target audience behavior, and competitive landscapes.",
              "Data-driven strategies that align with buyer preferences and industry forecasts."
            ]
          },
          {
            title: "Innovative Sales Strategies",
            details: [
              "Development of customized campaigns tailored to your project’s strengths and USP (Unique Selling Proposition).",
              "Integration of digital and traditional marketing tools to create a cohesive sales approach."
            ]
          },
          {
            title: "Lead Generation & Nurturing",
            details: [
              "Advanced CRM systems to identify, track, and manage potential buyers effectively.",
              "Automated lead-nurturing workflows to keep prospects engaged throughout their decision-making journey."
            ]
          },
          {
            title: "Tech Tools & Automation Systems Implementation",
            details: [
              "CRM & Automation Tools: Implementing systems that streamline customer interactions, lead management, and sales processes.",
              "Data Analytics & Reporting: Using data to track performance, identify trends, and make informed decisions for your brand.",
              "Customer Service Training: Equipping your team with the skills and knowledge to deliver exceptional service at every touchpoint."
            ]
          },
          {
            title: "Why Choose Us?",
            details: [
              "Expertise in designing sales strategies for both residential and commercial properties.",
              "Proven track record of success in boosting sales and enhancing client satisfaction.",
              "Commitment to delivering solutions that align with your business goals and brand identity."
            ]
          }
        ]
      },
      {
        sectionTitle: "Why Choose Us?",
        subsections: [
          "Expertise in designing sales strategies for both residential and commercial properties.",
          "Proven track record of success in boosting sales and enhancing client satisfaction.",
          "Commitment to delivering solutions that align with your business goals and brand identity."
        ]
      }
    ]
  },
  {
    id: 4,
    bannerImage: servicebannerone, 
    title: "Vendor Management Services",
    description: "In the fast-paced world of real estate, successful project completion depends on efficient collaboration with multiple vendors, from contractors and architects to material suppliers and service providers. At Deccan Progressive Realty, we take the complexity out of vendor management, offering a seamless process to ensure your projects are executed with precision, quality, and timeliness. \n \n Our Vendor Management Services are designed to simplify your real estate operations, enabling you to focus on strategic decisions while we handle the intricate details of managing vendors. With a proven track record and deep expertise in Hyderabad’s dynamic real estate market, we ensure that every aspect of vendor management is optimized for your success.",
        sections: [
      {
        sectionTitle: "Our Vendor Management Process",
        subsections: [
          {
            title: "Vendor Selection",
            details: [
              "Reputation and Experience: Ensuring that vendors have a proven track record in the real estate industry.",
              "Quality Standards: Verifying their ability to meet the required quality benchmarks."
            ]
          },
          {
            title: "Contract Negotiation",
            details: [
              "Pricing and Terms: Negotiating competitive rates and favorable contract terms.",
              "Scope of Work: Defining clear deliverables, timelines, and responsibilities."
            ]
          },
          {
            title: "Performance Monitoring",
            details: [
              "Regular Inspections: Conducting site visits to ensure work is progressing as per the agreed-upon standards.",
              "Quality Checks: Verifying that vendors are meeting quality benchmarks and addressing any issues promptly."
            ]
          }
        ]
      },
      {
        sectionTitle: "Why Vendor Management is Essential in Real Estate",
        subsections: [
          "Vendor management is a cornerstone of successful real estate project execution. From procurement to compliance, every vendor relationship impacts timelines, costs, and the quality of your project. Poorly managed vendors can lead to delays, cost overruns, and compromised quality.",
          "At Deccan Progressive Realty, we understand these challenges. That’s why our vendor management services are built around robust processes, strategic relationships, and hands-on oversight to ensure your real estate projects are delivered on time and within budget.",
        ]
      }
    ]
  },
  {
    id: 5,
    bannerImage: servicebannerone, 
    title: "Networking & Visibility Enhancement",
    description: "In a city as dynamic and fast-paced as Hyderabad, making your mark in the real estate sector requires more than just great properties—it demands visibility, a robust network, and a compelling presence. At Deccan Progressive Realty, we understand the nuances of the Hyderabad market and provide specialized Networking & Visibility Enhancement services that empower real estate professionals, developers, and businesses to thrive.\n \n Our holistic approach combines cutting-edge digital strategies, local expertise, and proven networking techniques to position your brand for success. Whether you're looking to attract high-value clients, establish strategic partnerships, or enhance your reputation, our services are designed to give you a competitive edge.",

    sections: [
      {
        sectionTitle: "Key Features of Networking & Visibility Enhancement Services",
        subsections: [
          {
            title: "Local Networking Events & Partnerships",
            details: [
              "Organizing and participating in industry events, conferences, and networking sessions to connect with key stakeholders.",
              "Establishing strategic partnerships with local businesses, influencers, and organizations to expand your reach and influence."

            ]
          },
          {
            title: "Digital Marketing & Social Media Engagement",
            details: [
              "Creating targeted digital marketing campaigns to reach your ideal audience and drive engagement.",
              "Leveraging social media platforms to showcase your properties, share industry insights, and build a community of followers."
            ]
          },
          {
            title: "PR & Media Relations",
            details: [
              "Securing media coverage in top publications, websites, and blogs to increase your brand visibility and credibility.",
              "Crafting compelling press releases, articles, and thought leadership pieces to position you as an industry expert."
            ]
          }
        ]
      },
      {
        sectionTitle: "Why Networking & Visibility Enhancement Matters",
        subsections: [
          "In a competitive market like Hyderabad, visibility is key to attracting high-value clients, investors, and partners.",
          "Networking opens doors to new opportunities, collaborations, and insights that can drive your business forward.",
          "Establishing a strong brand presence helps you stand out from the competition and build trust with your audience.",
          "Digital marketing and social media engagement are essential tools for reaching today’s tech-savvy buyers and investors.",
        ]
      }
    ]
  },
  {
    id: 6,
    bannerImage: servicebannerone, 
    title: "Tracking & Reporting Systems",
    description: "In today’s fast-paced real estate market, staying informed is not just an advantage—it’s a necessity. At Deccan Progressive Realty, we bring you state-of-the-art Tracking & Reporting Systems designed to deliver precise, real-time data about your real estate investments. Whether you're a buyer, seller, or investor, our advanced tools empower you to make smarter, data-driven decisions with confidence. With Hyderabad's dynamic real estate market in focus, our system integrates cutting-edge technology and deep market insights to ensure you never miss an opportunity.",
    
    
    sections: [
      {
        sectionTitle: "Key Features of Tracking & Reporting Systems",
        subsections: [
          {
            title: "Real-Time Market Insights",
            details: [
              "Access to up-to-date data on property prices, trends, and market conditions in Hyderabad.",
              "Customized reports and analytics to help you track your investments and make informed decisions."

            ]
          },
          {
            title: "Sales & Marketing Strategies",
            details: [
              "Performance tracking of your sales and marketing efforts to optimize your strategies and maximize returns.",
              "Competitor analysis to identify market gaps and opportunities for growth."
            ]
          },
          {
            title: "Investment Portfolio Management",
            details: [
              "Portfolio tracking to monitor the performance of your real estate investments and identify areas for improvement.",
              "Risk assessment and mitigation strategies to protect your investments and ensure long-term growth."
            ]
          }
        ]
      },
      {
        sectionTitle: "What Our Tracking & Reporting Systems Offer",
        subsections: [
          "Real-Time Insights: Stay ahead of the curve with access to the latest market data and trends.",
          "Data-Driven Decisions: Make informed choices based on accurate analytics and reports.",
          "Performance Optimization: Fine-tune your sales and marketing strategies for maximum impact.",
          "Risk Management: Identify potential risks and take proactive steps to safeguard your investments.",
        ]
      }
    ]
  },
  {
    id: 7,
    bannerImage: "servicebannerone",
    title: "Marketing Campaigns & Strategies",
    description: "In today’s competitive real estate market, simply listing properties is not enough. To capture the attention of potential buyers, you need innovative, targeted, and results-driven marketing strategies. At Deccan Progressive Realty, we specialize in crafting and executing comprehensive marketing campaigns that help you sell properties faster and at the best possible prices. \n \n With a blend of modern digital marketing and traditional offline techniques, we ensure your properties stand out in Hyderabad’s bustling real estate sector. Whether you’re a property developer, an investor, or a seller, our tailored marketing services are designed to maximize your property’s exposure and connect you with the right buyers.",
    
    sections: [
      {
        sectionTitle: "Core Elements of Our Marketing Approach",
        subsections: [
          {
            title: "In-Depth Market Research",
            details: [
              "Thorough analysis of market trends, consumer behavior, and competitive positioning.",
              "Insights-driven strategies to align with current and emerging real estate demands."
            ]
          },
          {
            title: "Innovative Campaign Design",
            details: [
              "Custom campaign creation that highlights your project's unique strengths and value propositions.",
              "Seamless integration of digital and traditional marketing channels for maximum outreach."
            ]
          },
          {
            title: "Effective Lead Management",
            details: [
              "Utilization of advanced CRM tools for efficient lead tracking and engagement.",
              "Strategic lead-nurturing workflows to build lasting client relationships."
            ]
          }
        ]
      },
      {
        sectionTitle: "Why Partner With Us?",
        subsections: [
          "Proven expertise in marketing residential and commercial properties effectively.",
          "Track record of driving measurable results through innovative marketing strategies.",
          "Commitment to aligning campaigns with your vision and business objectives."
        ]
      }
    ]
  },
  {
    id: 8,
    bannerImage: "servicebannerone",
    title: "Strategic Alliances & Associations",
    description: "Deccan Progressive Realty excels in forging strategic partnerships and associations to drive sustainable growth and expand opportunities. By building synergies with key industry players, we deliver value-driven collaborations for your real estate endeavors.",
    sections: [
      {
        sectionTitle: "Key Features of Our Strategic Alliances",
        subsections: [
          {
            title: "Market Analysis & Insights",
            details: [
              "Comprehensive research to identify potential partners and assess market alignment.",
              "Strategic insights to build collaborations that enhance mutual growth and innovation."
            ]
          },
          {
            title: "Tailored Partnership Strategies",
            details: [
              "Crafting alliances that resonate with your brand's objectives and target audience.",
              "Leveraging industry expertise to create impactful and result-oriented associations."
            ]
          },
          {
            title: "Sustainable Relationship Management",
            details: [
              "Implementing effective communication frameworks to maintain strong partnerships.",
              "Continuous evaluation and adaptation to ensure long-term success and value."
            ]
          }
        ]
      },
      {
        sectionTitle: "Why Choose Us?",
        subsections: [
          "Proven expertise in building partnerships across diverse real estate sectors.",
          "Track record of fostering collaborations that drive innovation and growth.",
          "Commitment to aligning strategic associations with your business goals."
        ]
      }
    ]
  },
  
  {
    "id": 9,
    "bannerImage": "servicebannerone",
    "title": "Management Consulting Services",
    "description": "Deccan Progressive Realty offers specialized Management Consulting Services to help real estate developers, investors, and businesses optimize their operations, enhance efficiency, and drive growth. Our team of experienced consultants brings a wealth of industry knowledge and strategic insights to deliver tailored solutions that address your unique challenges and opportunities. Whether you’re looking to streamline your processes, navigate organizational changes, or improve performance, our consulting services are designed to help you achieve your goals.",
    "sections": [
      {
        "sectionTitle": "Key Features of Our Consulting Services",
        "subsections": [
          {
            "title": "Strategic Planning & Advisory",
            "details": [
              "Tailored strategies to address unique business challenges and opportunities.",
              "Expert guidance on aligning operations with organizational objectives."
            ]
          },
          {
            "title": "Operational Excellence",
            "details": [
              "Process optimization to enhance efficiency and reduce operational costs.",
              "Implementation of best practices to ensure consistent performance improvement."
            ]
          },
          {
            "title": "Change Management",
            "details": [
              "Comprehensive plans to navigate organizational transitions effectively.",
              "Engagement strategies to ensure stakeholder alignment and commitment."
            ]
          }
        ]
      },
      {
        "sectionTitle": "Why Choose Us?",
        "subsections": [
          "Proven expertise in management consulting for diverse real estate projects.",
          "Track record of delivering measurable improvements in efficiency and growth.",
          "Commitment to providing actionable insights and long-term solutions."
        ]
      }
    ]
  }
  

];

const SingleService = () => {
  const { id } = useParams();  
  const service = tabsData.find(tab => tab.id.toString() === id);  

  if (!service) {
    return <Typography variant="h5">Service not found</Typography>;
  }

  return (
    <div>
      <Box sx={{ mb: 4 }}>
        {/* Tab Banner Image */}
        <Box
          component="img"
          src={service.bannerImage}
          alt={service.title}
          sx={{
            width: '100%',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
            },
            marginBottom: '1rem',
          }}
        />
        <Typography variant="h5" color="primary" sx={{ mb: 2, fontWeight: 600,textAlign: 'left' }}>
          {service.title}
        </Typography>

        {/* Tab Description */}
        <Typography variant="body2" sx={{ mb: 3, textAlign: 'justify' }}>
          {service.description.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))} 
        </Typography>

        {/* Tab Sections */}
        {service.sections.map((section, sectionIndex) => (
          <Box key={sectionIndex} sx={{ mb: 4 }}>
            <Typography variant="h6" color="primary" sx={{ fontWeight: 600, textAlign: 'left' }}>
              {section.sectionTitle}
            </Typography>

            {/* List of Subsections */}
            {section.subsections.map((subsection, subIndex) => (
              <Box key={subIndex} sx={{ mb: 2 }}>
                {/* Check if subsection has details */}
                {typeof subsection === 'object' && 'details' in subsection ? (
                  <>
                    <Typography variant="body2" sx={{ fontWeight: 600, mb: 1 ,textAlign:'left',mt:2}}>
                      {subsection.title}
                    </Typography>
                    <List>
                      {subsection.details.map((detail, detailIndex) => (
                        <ListItem key={detailIndex} sx={{ display: 'flex', alignItems: 'flex-start', gap: '5px', padding: 0 }}>
                          <FiberManualRecordIcon sx={{ fontSize: '10px', color: '#30779d', marginTop: '4px' }} />
                          <Typography variant="body2">{detail}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </>
                ) : (
                  <List>
                  <ListItem sx={{ display: 'flex', alignItems: 'flex-start', gap: '5px', padding: 0 }}>
                    <FiberManualRecordIcon sx={{ fontSize: '10px', color: '#30779d', marginTop: '4px' }} />
                    <Typography variant="body2">{subsection}</Typography>
                  </ListItem>
                </List>
                )}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default SingleService;
