import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  borderRadius: '30px',
  border: '1px solid black',
  boxShadow: 'none',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const PopularTags: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <Box sx={{ background: 'white', padding: '30px', borderRadius: '20px' }}>
      <Box sx={{ textAlign: 'start' }}>
        <h4>Popular Tags</h4>
      </Box>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Item>
            <span
              onClick={() => handleNavigation('/blog/1')}
              style={{
                textDecoration: 'none',
                color: 'black',
                fontSize: '15px',
                fontWeight: '300',
                marginBottom: '8px',
                cursor: 'pointer',
              }}
            >
              For Sale
            </span>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <span
              onClick={() => handleNavigation('/blog/2')}
              style={{
                textDecoration: 'none',
                color: 'black',
                fontSize: '15px',
                fontWeight: '300',
                marginBottom: '8px',
                cursor: 'pointer',
              }}
            >
              Houses
            </span>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <span
              onClick={() => handleNavigation('/blog/1')}
              style={{
                textDecoration: 'none',
                color: 'black',
                fontSize: '15px',
                fontWeight: '300',
                marginBottom: '8px',
                cursor: 'pointer',
              }}
            >
              Apartments
            </span>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <span
              onClick={() => handleNavigation('/blog/2')}
              style={{
                textDecoration: 'none',
                color: 'black',
                fontSize: '15px',
                fontWeight: '300',
                marginBottom: '8px',
                cursor: 'pointer',
              }}
            >
              Villa
            </span>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <span
              onClick={() => handleNavigation('/blog/1')}
              style={{
                textDecoration: 'none',
                color: 'black',
                fontSize: '15px',
                fontWeight: '300',
                marginBottom: '8px',
                cursor: 'pointer',
              }}
            >
              Commercial Spaces
            </span>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <span
              onClick={() => handleNavigation('/blog/2')}
              style={{
                textDecoration: 'none',
                color: 'black',
                fontSize: '15px',
                fontWeight: '300',
                marginBottom: '8px',
                cursor: 'pointer',
              }}
            >
              Land for Sale
            </span>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <span
              onClick={() => handleNavigation('/blog/1')}
              style={{
                textDecoration: 'none',
                color: 'black',
                fontSize: '15px',
                fontWeight: '300',
                marginBottom: '8px',
                cursor: 'pointer',
              }}
            >
              Rentals
            </span>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <span
              onClick={() => handleNavigation('/blog/2')}
              style={{
                textDecoration: 'none',
                color: 'black',
                fontSize: '15px',
                fontWeight: '300',
                marginBottom: '8px',
                cursor: 'pointer',
              }}
            >
              Luxury Properties
            </span>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PopularTags;
